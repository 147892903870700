<template>
  <div class="content-container">
    <a-tabs type="card" @change="tabChange">
      <a-tab-pane key="1" tab="Данные дилера">
        <ClientData />
      </a-tab-pane>
      <!--      <a-tab-pane key="2" tab="Контактная информация">-->
      <!--        <ContactInfo />-->
      <!--      </a-tab-pane>-->
      <!--      <a-tab-pane key="3" tab="Наценки">-->
      <!--        <Markups />-->
      <!--      </a-tab-pane>-->

      <!--      <a-tab-pane key="4" tab="Кредитный лимит">-->
      <!--        <CreditLimit />-->
      <!--      </a-tab-pane>-->
    </a-tabs>
  </div>
</template>

<script>
import ClientData from "@/components/clients/ClientData"
// import ContactInfo from "@/components/clients/ContactInfo"
// import Markups from "@/components/clients/Markups"
// import CreditLimit from "@/components/clients/CreditLimit"
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "TabsInfo",
  async mounted() {
    const clientId =
      this.$route.name === "ClientInfoCard"
        ? this.user.client.id
        : this.$route.params.id
    await this.getClient(clientId)
    //this.setCreateFormContacts()
  },
  components: {
    ClientData,
    // ContactInfo,
    // Markups,
    // CreditLimit,
  },
  computed: mapGetters({
    user: "auth/user",
    createForm: "clients/createForm",
    clientTypeId: "clients/clientTypeId",
  }),
  watch: {
    createForm() {
      //this.setCreateFormContacts()
    },
  },
  methods: {
    tabChange() {
      console.log("tab changed")
    },
    ...mapActions({
      getClient: "clients/getClient",
    }),
    ...mapMutations({
      setCreateForm: "clients/SET_CREATE_FORM",
    }),
    setCreateFormContacts() {
      const contactFields = this.createForm.contacts
      let findObj = contactFields.find(item => item.type === "Взаиморасчеты")
      if (!findObj) {
        contactFields.push({
          type: "Взаиморасчеты",
          name: "",
          phone: "",
          email: "",
        })
      }
      findObj = null
      findObj = contactFields.find(
        item => item.type === "Отправка прайс-листов"
      )
      if (!findObj) {
        contactFields.push({
          type: "Отправка прайс-листов",
          name: "",
          phone: "",
          email: "",
        })
      }
      findObj = null
      findObj = contactFields.find(item => item.type === "Руководитель")
      if (!findObj) {
        contactFields.push({
          type: "Руководитель",
          name: "",
          phone: "",
          email: "",
        })
      }
      findObj = null
      findObj = contactFields.find(item => item.type === "Заказы")
      if (!findObj) {
        contactFields.push({
          type: "Заказы",
          name: "",
          phone: "",
          email: "",
        })
      }
      findObj = null
      findObj = contactFields.find(item => item.type === "Возвраты")
      if (!findObj) {
        contactFields.push({
          type: "Возвраты",
          name: "",
          phone: "",
          email: "",
        })
      }
      /*findObj = null
      findObj = contactFields.find(item => item.type === "Обновление паролей")
      if (!findObj) {
        contactFields.push({
          type: "Обновление паролей",
          name: "",
          phone: "",
          email: "",
        })
      }
      findObj = null
      const newCreateForm = this.createForm
      newCreateForm.contacts = contactFields
      this.setCreateForm(newCreateForm)*/
    },
  },
}
</script>

<style scoped></style>
